import React from "react";

interface Inpainting{
  s3Key:string,
  bucket:string,
  presignedUrl:undefined|string,
}
interface EditorContextInterface{
  videoCreated:boolean,
  outputVideoUrl:string|undefined,
  outputVideoObjectUrl:string|undefined,
  outputPresignedUrl:string|undefined,
  outputVideoKey:string|undefined,
  outputVideoBucket: string|undefined,
  isEdited:boolean,
  screenEffectList:any[],
  sceneTextShadowEffectList:any[],
  ttsVolumeList:number[],
  bgmVolume:number,
  autoExport:boolean,
  eraserList:Inpainting[],
  genBgList:Inpainting[],

  seenEditorTutorial:boolean,

  setVideoCreated:(created:boolean)=>void,
  setOutputVideoUrl:(newUrl:string|undefined)=>void,
  setOutputVideoObjectUrl:(newUrl:string|undefined)=>void,
  setOutputPresignedUrl:(newPresigned:string|undefined)=>void,
  setOutputVideoKey:(newKey:string|undefined)=>void,
  setOutputVideoBucket:(newBucket:string|undefined)=>void,
  setIsEdited:(newVale:boolean)=>void,
  setScreenEffectList:(newList)=>void,
  setSceneTextShadowEffectList:(newList)=>void,
  setTtsVolumeList:(newList)=>void,
  setBgmVolume:(newVal)=>void,
  setAutoExport:(newState)=>void,
  setEraserList:(newList)=>void,
  setGenBgList:(newList)=>void,
  setSeenEditorTutorial:(newVal)=>void,
}

const EditorContextDefault:EditorContextInterface={
  videoCreated:false,
  outputVideoUrl:undefined,
  outputVideoObjectUrl:undefined,
  outputPresignedUrl:undefined,
  outputVideoKey:undefined,
  outputVideoBucket:undefined,
  isEdited:false,
  screenEffectList:[],
  sceneTextShadowEffectList:[],
  ttsVolumeList:[],
  bgmVolume:100,
  autoExport:false,
  eraserList:[],
  genBgList:[],
  seenEditorTutorial:false,

  setVideoCreated:()=>{},
  setOutputVideoUrl:()=>{},
  setOutputVideoObjectUrl:()=>{},
  setOutputPresignedUrl:()=>{},
  setOutputVideoKey:()=>{},
  setOutputVideoBucket:()=>{},
  setIsEdited:()=>{},
  setScreenEffectList:()=>{},
  setSceneTextShadowEffectList:()=>{},
  setTtsVolumeList:()=>{},
  setBgmVolume:()=>{},
  setAutoExport:()=>{},
  setEraserList:()=>{},
  setGenBgList:()=>{},
  setSeenEditorTutorial:()=>{}
}

export const EditorContext = React.createContext(EditorContextDefault)