import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { NarrationTabContainer } from "./TabContainer";
import { TabHeader } from "./TabHeader";
import { EditorPageContext } from "../../editorPageContext";
import Button from "../../../../Components/common/Button";
import LoadingScreen from "../../../../Components/common/LoadingScreen";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import actorDataJson from "../../../../Data/actorData.json"
import { WebPostCreateSpeak, WebGetCreateSpeak } from "../../../../Api/ai";
import { WebGetUrl } from "../../../../Api/resource";
import { EditorContext } from "../../../../Contexts/EditorContext";
import { useTranslation } from "react-i18next";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";

const StyledNarrationPanel = styled.div`
  align-items: flex-start;
  background-color: var(--tokens-color-background-primary-duplicate);
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
  // left: 1104px;
  // height: 400px;
  // min-height: 400px;
  padding: var(--tokens-spacing-07-duplicate) var(--tokens-spacing-11-duplicate) var(--tokens-spacing-07-duplicate)
    var(--tokens-spacing-07-duplicate);
  // position: absolute;
  // top: 0px;
  width: 336px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: ${(props)=>props.theme.spacing["spacing-04"]};
  // right: ${(props)=>props.theme.spacing["spacing-14"]};
  right:46px;
`

export const NarrationPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectNum, setSelectNum] = useState(0);
  const {isLoading, setIsLoading} = useContext(EditorPageContext);

  const [selectedActor, setSelectedActor] = useState("")
  const [ntrText, setNtrText] = useState("")
  const [ntrVolume, setNtrVolume] = useState(100)

  const editorPageContext = useContext(EditorPageContext)
  const editorContext = useContext(EditorContext)
  const scenarioContext = useContext(ScenarioContext)
  const shopInfoContext = useContext(ShopInfoContext)


  useEffect(()=>{

    const index = editorPageContext.selectIndex
    if(index===undefined){return}

    const text = scenarioContext.finalScenario.scenes[index]?.narrationInfo.text;
    const actor = scenarioContext.editedTTSActorList[index];
    const volume = editorContext.ttsVolumeList[index];

    setNtrText(text)
    setSelectedActor(actor)
    setNtrVolume(volume)
    
  },[editorPageContext.selectIndex])

  useEffect(()=>{
    const index = editorPageContext.selectIndex
    if(index===undefined){return}
    const text = scenarioContext.finalScenario.scenes[index].narrationInfo.text;
    setNtrText(text)
  },[scenarioContext.finalScenario])

  useEffect(()=>{
    const index = editorPageContext.selectIndex
    if(index===undefined){return}
    const actor = scenarioContext.editedTTSActorList[index];
    setSelectedActor(actor)
  },[scenarioContext.editedTTSActorList])

  useEffect(()=>{
    const index = editorPageContext.selectIndex
    if(index===undefined){return}
    const volume = editorContext.ttsVolumeList[index];
    setNtrVolume(volume)
  },[editorContext.ttsVolumeList])

  const handleSelect = (num: number) => {
    setSelectNum(num);
  }

  const handleClickApply = () => {
    genTTS(selectedActor);
  }


  async function genTTS(voiceCharacter){

    if(editorPageContext.selectIndex === undefined){return}

    if(ntrText && (ntrText!=="" && ntrText!==" ")){
      setIsLoading(prev=>true)
      const idx = actorDataJson[shopInfoContext.language].findIndex(actor=>actor.name===voiceCharacter);
      const actorInfo = actorDataJson[shopInfoContext.language][idx];

      const actorId = shopInfoContext.language==="ko-kr"? actorInfo.id: actorInfo.name
  
      const createTTSResponse = await WebPostCreateSpeak({
        assetId: sessionStorage.getItem("ASSET_ID"),
        token: process.env.REACT_APP_NTR_TOKEN,
        text:ntrText,
        actorId: actorId,
        // language:"auto",
        languageCode: shopInfoContext.language
      })

      if(createTTSResponse.result===0){
        console.log("tts url ::" , createTTSResponse.speakResult.speak_v2_url);
        try{
          const ttsPromise:any = await new Promise(async(resolve, reject)=>{
            const ttsInterval = setInterval(async ()=>{
              const getTTSResponse = await WebGetCreateSpeak({
                assetId: sessionStorage.getItem("ASSET_ID"),
                sceneNo: editorPageContext.selectIndex,
                speakUrl: shopInfoContext.language==="ko-kr"? createTTSResponse.speakResult.speak_v2_url : "None",
                taskId: shopInfoContext.language==="ko-kr"? "None" : createTTSResponse.speakResult.taskId
              },process.env.REACT_APP_NTR_TOKEN)
              if(getTTSResponse.result===0){
                switch(getTTSResponse.status){
                  case "progress":
                    console.log("create tts on progress...")
                    break;
                  case "done":
                    clearInterval(ttsInterval);
                    console.log("create tts finished")
                    resolve(getTTSResponse.speakResult);
                    break;
                  case "failed":
                    console.error("get TTS failed : ", getTTSResponse.errMsg);
                    clearInterval(ttsInterval);
                    reject(`get TTS error : ${getTTSResponse.errMsg}`)
                    break;
                }
              }else{
                console.error("get TTS error : ", getTTSResponse.errMsg);
                clearInterval(ttsInterval);
                reject(`get TTS error : ${getTTSResponse.errMsg}`)
              }
            }, 500)
          })
          const ttsUrl = await WebGetUrl({
            assetId: sessionStorage.getItem("ASSET_ID"),
            bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
            key: ttsPromise.path
          })

          let newList = JSON.parse(JSON.stringify(editorPageContext.ntrUrlList));
          newList[editorPageContext.selectIndex] = ttsUrl.presignedUrl;
          editorPageContext.setNtrUrlList(newList);

          
          const index = editorPageContext.selectIndex
          
          let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
          finalScenario.scenes[index].narrationInfo.text = ntrText;
          finalScenario.scenes[index].narrationInfo.path = ttsPromise.path;
          finalScenario.scenes[index].narrationInfo.duration = ttsPromise.duration;
          scenarioContext.setFinalScenario(finalScenario)

          let actorList = JSON.parse(JSON.stringify(scenarioContext.editedTTSActorList))
          actorList[index] = selectedActor;
          scenarioContext.setEditedTTSActorList(actorList);

          let volumeList = JSON.parse(JSON.stringify(editorContext.ttsVolumeList))
          volumeList[index] = ntrVolume;
          editorContext.setTtsVolumeList(volumeList);

          // if(ttsUrl.result===0){
          //   setTtsUrl(ttsUrl.presignedUrl)
          // }

        }catch(err){console.error("TTS error ", err)}
      }
    }
    setIsLoading(prev=>false)
  }

  return (
    <StyledNarrationPanel>
      <TabHeader selectNum={selectNum} onClick={handleSelect}/>
      {selectNum===0 &&<NarrationTabContainer actor={selectedActor} setActor={setSelectedActor} text={ntrText} setText={setNtrText} volume={ntrVolume} setVolume={setNtrVolume}/>}
      {editorPageContext.selectType==="ntr" &&
      <ButtonWrapper>
        <Button
          $buttonType="text-s"
          $variant="brand1-subtle"
          text={t("com-pages-editor.apply")}
          onClick={handleClickApply}
        />
      </ButtonWrapper>
      }
      {
        isLoading && 
        <div style={{position:"fixed", zIndex:99, top:0, left:0, width:"100vw", height:"100vh", boxSizing:"border-box", background:'rgba(0,0,0,0.2)'}}>
          <LoadingScreen text=" "/>
        </div>
      }
    </StyledNarrationPanel>
  );
};
