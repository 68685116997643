import React, { useContext, useEffect, useState, useRef } from "react";
// import styled from "styled-components";
import Container from "../../Components/Container";
import VideoLoading from "../../Components/pages/EditVideo/VideoLoading";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/common/Button";
import VideoInfo from "../../Components/pages/EditVideo/VideoInfo";
// import PlayerController from "../../Components/pages/EditVideo/Player";
import TimelineBlock from "../../Components/pages/EditVideo/TimelineBlock";

import VideoPlayer from "../../Components/pages/EditVideo/VideoPlayer";
import { ShopInfoContext } from "../../Contexts/ShopInfoContext";

import {VideoFrameStyle,FootStyle,FootRightStyle,LoadingFrameStyle} from "./EditVideo.styled"
// import Scene from "../../Components/pages/CreateScenario/Scene";
import Scene from "../../Components/pages/CreateScenario/Scene";
import { ScenarioContext } from "../../Contexts/ScenarioContext";

// import sceneData from "../../Data/sceneData.json"
import screenEffect from '../../Data/screenEffect.json'
import textEffect from '../../Data/textEffect.json'
import fontData from "../../Data/fontData.json"
import templateData from "../../Data/templateData.json"

import { ReactComponent as QuestionIcon } from "../../Assets/ModalIllu/check.svg";
import AlertModal from "../../Components/common/AlertModal";
import { resolve } from "path";
import EditScene from "../../Components/pages/EditVideo/EditScene";
import { WebGetConcat, WebGetConcatFile, WebGetRenderScene, WebPostConcat, WebPostRenderScene, WebSave } from "../../Api/resource";
import { EditorContext } from "../../Contexts/EditorContext";

import { isLogging } from "../../App";
import { ShopPointContext } from "../../Contexts/ShopPointContext";

import { Trans, useTranslation } from "react-i18next";
import { callHibernation } from "../../Utilities";

// import { createCanvas, loadImage, registerFont} from ('canvas')


// const IS_DEV = true;
// const USE_TEMPLATE = true;

const scrolling = true;

function runRullet(chance:number){
  const dice = Math.random()*100;
  if(dice<=chance){
    return true
  }else{
    return false
  }
}

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function EditVideo(props){
  const { t } = useTranslation();
  const userName = sessionStorage.getItem("USER_NAME")

  const shopInfoContext = useContext(ShopInfoContext)
  const shopPointContext = useContext(ShopPointContext)

  const mainText = t("pages-editvideo.m1");
  const subText = t("pages-editvideo.m2", { userName });

  const [genProgress, setGenProgress] = useState(0);

  let progress = 0;
  const [loadingInterval, setLoadingInterval] = useState(undefined);

  const [videoCreated, setVideoCreated] = useState(false);

  const [selectedScene, setSelectedScene] = useState(undefined);

  const [dateString, setDateString] = useState("");

  const scenarioContext = useContext(ScenarioContext);

  const [hasExecuted, setHasExecuted] = useState(false);

  const [outputURL, setOutputURL] = useState(undefined);
  const [s3VideoUrl, setS3VideoUrl] = useState(undefined);

  const [showRenderFailAlert, setShowRenderFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const closeSuccessAlert = () => {setShowSuccessAlert(false)};
  const closeFailAlert = () => {setShowFailAlert(false)};
  const closeRenderFailAlert = () =>{setShowRenderFailAlert(false)}
  const [prevCategory, setPrevCategory] = useState(undefined);
  const [alertIcon, setAlertIcon] = useState(<QuestionIcon/>);
  const [alertTitle, setAlertTitle] = useState(undefined);
  const [alertDescription, setAlertDescription] = useState(undefined);

  const [videoMetadataLoaded, setVideoMetadataLoaded] = useState(false);
  const [outputVideoDuration, setOutputVideoDuration] = useState(0);

  const [started, setStarted] = useState(false);
  const startedRef = useRef(false);

  const intervalIdsRef = useRef([]);

  const editorContext = useContext(EditorContext);

  useEffect(()=>{setHasExecuted(true)},[])

  useEffect(()=>{
    callHibernation();
    if(hasExecuted){
      if(!editorContext.videoCreated){
        if(!scenarioContext.finalScenario){
          props.setStep(1);
          nav("/")
        }else{
          // console.log("scenes :: ", scenarioContext.finalScenario)
          setVideoCreated(false)
          genVideo();
        }
      }else{
        if(editorContext.outputPresignedUrl){
          setS3VideoUrl(editorContext.outputPresignedUrl);
          if(editorContext.outputVideoUrl){
            setOutputURL(editorContext.outputVideoUrl)
          }
          setVideoCreated(true)
        }else{
          props.setStep(1);
          nav("/")
        }
      }
    }
  },[hasExecuted])    


  //// 편집페이지에서 내보내기로 돌아온 후 영상이 만들어지면 저장
  useEffect(()=>{
    if(editorContext.autoExport && videoCreated && videoMetadataLoaded && (outputVideoDuration!==0)){
      handleClickSave();
    }
  },[videoCreated, videoMetadataLoaded, outputVideoDuration])

  function matchTextEffect(target){
    if(target === "search"){
      return target
    }
    console.log("target :: ",target)
    const index = textEffect.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      return textEffect[index].name
    }
  }
  function matchScreenEffect(target){
    const index = screenEffect.findIndex(item=>item.effect===target)
    if(index<0){
      return 'slide_right'
    }else{
      return screenEffect[index].name
    }
  } 


  async function genVideo(){
    shopInfoContext.setIsGenerating(true);

    const assetId = sessionStorage.getItem("ASSET_ID");

    let videoLength = 0;
    
    const videoRequestList = [];
    let TOTAL_RENDER_COUNT = 0;   


    let sceneData = scenarioContext.finalScenario?.scenes;
    const useTemplate = scenarioContext.useTemplate

    ////////////////// anticollision/////////////////
    
    
    if(!editorContext.isEdited){
      for(let sceneIndex=0; sceneIndex<sceneData.length; sceneIndex++){
        let collided = false;
        const scene=sceneData[sceneIndex]
        
        if(!sceneData[sceneIndex].filter){
          sceneData[sceneIndex].filter={
            name:"none",
            intensity:1
          };
        }

  
        console.group(`Scene${sceneIndex} collision check`)
        for(let textIndex=0; textIndex<scene.textList.length; textIndex++){
          const text = scene.textList[textIndex];
          for(let target=textIndex+1; target<scene.textList.length; target++){  
            console.log(`compare ${textIndex}-${target}`);
            if((text.posY <= scene.textList[target].posY)  && (text.posY+text.height >= scene.textList[target].posY)){
              console.log("collision warning");
              collided = true;
            }
          }        
        }
  
        if(collided){
          console.log("solving collision");
          const initialY = scene.textList[0].posY;
          const height = scene.textList[0].height;
          let tempTextList = scene.textList.map((text, index)=>{
            let tempText = JSON.parse(JSON.stringify(text));
            tempText.posY = initialY + index*(height+30);
            tempText.height = height;
            return tempText
          })
          scene.textList=tempTextList;
        }
        console.groupEnd();
      }

      let dice = Math.random();
      let lastSceneType = Math.floor(Math.random()*2);

      // 0: 검색창
      // 1: 상호명
      if(dice<0.5){
        lastSceneType = 0
      }else{
        lastSceneType = 1
      }
  
      if(isLogging){
        console.log("last scene :: ", lastSceneType)
      }
      // let lastSceneType = 1;
      // sceneData[sceneData.length-1]
      if(scenarioContext.useLastEnding){
        if(lastSceneType===0){
          // alert("ENDING :: 하얀 검색창")
          console.log("ENDING :: 하얀 검색창")
        }else{
          // alert("ENDING :: 상호명")
          console.log("ENDING :: 상호명")
        }
        scenarioContext.setEditedTTSActorList([...scenarioContext.editedTTSActorList, scenarioContext.editedTTSActorList[0]])
      }else{
        // alert("ENDING :: 이미지 검색창");
        console.log("ENDING :: 이미지 검색창")
      }  
    
      
      if(lastSceneType===0){
        let textPosX=0;
        const shopNameLength = shopInfoContext.shopName.length;
        if(shopInfoContext.language==="en-us"){
          if(shopNameLength<=5){textPosX=775}
          // else if(shopNameLength<=5){textPosX=648}
          // else if(shopNameLength<=8){textPosX=549}
          // else if(shopNameLength<=13){textPosX=419}
          // else {textPosX=289}
          else if(shopNameLength<=9){textPosX=628}
          else if(shopNameLength<=15){textPosX=528}
          else if(shopNameLength<=20){textPosX=397}
          else {textPosX=267}
          textPosX+=70
        }else{
          if(shopNameLength<=3){textPosX=775}
          // else if(shopNameLength<=5){textPosX=648}
          // else if(shopNameLength<=8){textPosX=549}
          // else if(shopNameLength<=13){textPosX=419}
          // else {textPosX=289}
          else if(shopNameLength<=6){textPosX=628}
          else if(shopNameLength<=9){textPosX=528}
          else if(shopNameLength<=14){textPosX=397}
          else {textPosX=267}
        }
        ////// 검색창에 검색하세요  
        
        const name = shopInfoContext.shopName
        const charCode = name.charCodeAt(name.length - 1);          
        const consonantCode = (charCode - 44032) % 28;

        let searchDescription = t("pages-editvideo.m3", {name});
        if(consonantCode === 0){
          searchDescription=  t("pages-editvideo.m3_1", {name});
        }
  
        const newData={
          backgroundColor: "#000000",
          colorList: ["#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF"],
          desc: searchDescription,
          effect: "없음",
          fileName: "search",
          layoutList: [
              {
                  fileList4AiImage: [],
                  fileList4AiVideo: [],
                  height: 1080,
                  path: "search",
                  posX: 0,
                  posY: 0,
                  tabIndex: 0,
                  type: "last",
                  width: 1920
              }
          ],
          narrationInfo: {
              duration: 0,
              path: "",
              text: ""
          },
          no: sceneData.length+1,
          promptI2V: "",
          promptT2I: "",
          textList: [
              {
                  effect: "typing",
                  font: sceneData[0].textList[0].font,
                  fontColor: "#000000",
                  height: 45,
                  posX: textPosX,
                  posY: 517,
                  text: shopInfoContext.shopName,
                  width: 0
              }
          ],
          time: 1.5,
          title: "Last scene",
          type: "last",
          filter:{
            name:"none",
            intensity:1
          }
        }
        if(scenarioContext.useLastEnding){
          sceneData.push(newData);
          sceneData[sceneData.length-2].effect="페이드아웃 화면전환"
        }
      }else{
        ////// 상호, 주소 전화번호
  
        const selectedFont = sceneData[0].textList[0].font
  
        const fontIdx = fontData.findIndex(font=>font.fontName === selectedFont);
        const fontFamily = fontData[fontIdx]["font-family"];
  
        let shopNameSize = 200;
        const fontConfig = `${shopNameSize}px ${fontFamily}`
        let shopNameWidth = getTextWidth(shopInfoContext.shopName, fontConfig);
        let shopNamePosX = 960-shopNameWidth/2
        if(shopNameWidth>1720){
          const ratio = 1720/shopNameWidth;
          shopNameSize = 200*ratio;
          const newFontConfig = `${shopNameSize}px ${fontFamily}`;
          const newShopNameWidth = getTextWidth(shopInfoContext.shopName, newFontConfig);
          shopNamePosX = 960 - newShopNameWidth/2;
        }
  
        const newData={
          backgroundColor: "#000000",
          colorList: ["#000000","#000000","#000000","#000000","#000000"],
          desc: t("pages-editvideo.m4"),
          effect: "없음",
          fileName: "search",
          layoutList: [
              {
                  fileList4AiImage: [],
                  fileList4AiVideo: [],
                  height: 1080,
                  path: "black",
                  posX: 0,
                  posY: 0,
                  tabIndex: 0,
                  type: "last",
                  width: 1920
              }
          ],
          narrationInfo: {
              duration: 0,
              path: "",
              text: ""
          },
          no: sceneData.length+1,
          promptI2V: "",
          promptT2I: "",
          textList: [
              {
                  effect: "none",
                  font: sceneData[0].textList[0].font,
                  fontColor: "#FFFFFF",
                  height: shopNameSize,
                  posX: shopNamePosX,
                  posY: 300,
                  text: shopInfoContext.shopName,
                  width: 0
              },
              {
                effect: "none",
                font: sceneData[0].textList[0].font,
                fontColor: "#FFFFFF",
                height: 44,
                posX: 120,
                posY: 840,
                text: `Tel\\: ${shopInfoContext.shopCall.join('-')}`,
                width: 0
            },
            {
              effect: "none",
              font: sceneData[0].textList[0].font,
              fontColor: "#FFFFFF",
              height: 42,
              posX: 120,
              posY: 930,
              text: shopInfoContext.shopAddress,
              width: 0
          }
          ],
          time: 1.5,
          title: "Last scene",
          type: "last",
          filter:{
            name:"none",
            intensity:1
          }
        }
        if(scenarioContext.useLastEnding){
          sceneData.push(newData)
          sceneData[sceneData.length-2].effect="왼쪽으로 슬라이드"
        }
      }
      
  
  
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
  
      // const useTemplate = scenarioContext.useTemplate
  
      if(useTemplate){
        const templateIndex = scenarioContext.selectedTemplate;
        const template = templateData.baseScene[templateIndex];
        if(isLogging){
          console.log(`selected template ::: ${templateIndex}`, template)
        }
    
        let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
        for(let idx=0; idx<sceneData.length; idx++){
          console.group(`Scene${idx}`)
          console.log(`Scene${idx} ::: `, sceneData[idx]);

          let correctionTop = 0;
          let correctionLeft = 0;
          let correctionBottom = 0;

          for(let txtIdx=0; txtIdx<sceneData[idx].textList.length; txtIdx++){
            console.group(`Scene${idx} text${txtIdx}`)
            // sceneData[idx].textList[txtIdx].posX = template.textPos[idx%template.textPos.length][txtIdx%template.textPos[idx].length].posX
            // sceneData[idx].textList[txtIdx].posY = template.textPos[idx%template.textPos.length][txtIdx%template.textPos[idx].length].posY
            // sceneData[idx].textList[txtIdx].height = template.textPos[idx%template.textPos.length][txtIdx%template.textPos[idx].length].height
            sceneData[idx].textList[txtIdx].rotate = template.textPos[idx%template.textPos.length][txtIdx%template.textPos[idx].length].rotate || 0;

            // sceneData[idx].textList[txtIdx].effect = template.textEffect[idx%template.textPos.length][txtIdx%template.textPos[idx].length].effect

            const textFont = sceneData[idx].textList[txtIdx].font
            let fontIdx = fontData.findIndex(font=>font.fontName === textFont);
            if(fontIdx<0){
              fontIdx = 0;
            }
            const fontFamily = fontData[fontIdx]["font-family"];

            let xPos =  sceneData[idx].textList[txtIdx].posX;
            let yPos =  sceneData[idx].textList[txtIdx].posY;
            let fontSize =  sceneData[idx].textList[txtIdx].height;
            const rotate = sceneData[idx].textList[txtIdx].rotate||0;
            const text = sceneData[idx].textList[txtIdx].text;

            let textWidth = await getTextWidth(text, `${fontSize}px ${fontFamily}`);

            const sinVal = Math.abs(Math.sin(rotate * (Math.PI/180)));
            const cosVal = Math.abs(Math.cos(rotate * (Math.PI/180)));

            let textMetric = {
              posX : xPos,
              posY : yPos,
              left : (rotate<0)? 0 : fontSize*sinVal,
              right: (rotate<0)? textWidth*cosVal + fontSize*sinVal : textWidth*cosVal,
              top : (rotate<0)? textWidth*sinVal : 0,
              bottom : (rotate<0)? fontSize*cosVal : textWidth*sinVal + fontSize*cosVal,
            }

            console.log(`Text height/width original :: ${fontSize}/${textWidth}`)

            console.log(`Scene${idx} text${txtIdx} metric`, JSON.parse(JSON.stringify(textMetric)))

            const textPosIndex = template.textPos[idx%template.textPos.length][0].index;
            let centerY = true;
            let centerX = true;
            
            switch(textPosIndex){
              case 4:
                if(sceneData[idx].textList.length===1){
                  centerY=true;
                  centerX=true;
                }else{
                  centerY=false;
                  centerX=true;
                }
                break;
              case 3:
              case 5:
                if(sceneData[idx].textList.length===1){
                  centerY=true;
                }else{
                  centerY=false;
                }
                centerX=false;
                break;
              case 1:
              case 7:
                centerX = true;
                centerY = false;
                break;
              default:
                centerX=false;
                centerY=false;
                break;
            }

            if(textMetric.left + textMetric.right > 1720){
              // 텍스트 너비가 최대 너비를 넘으면 리사이징
              fontSize = fontSize * (1720/(textMetric.left + textMetric.right));
              textWidth = await getTextWidth(text, `${fontSize}px ${fontFamily}`);
              console.log(`Text height/width with width adjust :: ${fontSize}/${textWidth}`)
              textMetric = {
                posX : xPos,
                posY : yPos,
                left : (rotate<0)? 0 : fontSize*sinVal,
                right: (rotate<0)? textWidth*cosVal + fontSize*sinVal : textWidth*cosVal,
                top : (rotate<0)? textWidth*sinVal : 0,
                bottom : (rotate<0)? fontSize*cosVal : textWidth*sinVal + fontSize*cosVal,
              }
            }

            if( textMetric.top + textMetric.bottom > 950){
              // 텍스트 높이가 최대 높이를 넘으면 리사이징
              fontSize = fontSize * (950/(textMetric.top + textMetric.bottom));
              textWidth = await getTextWidth(text, `${fontSize}px ${fontFamily}`);
              console.log(`Text height/width with height adjust :: ${fontSize}/${textWidth}`)
              textMetric = {
                posX : xPos,
                posY : yPos,
                left : (rotate<0)? 0 : fontSize*sinVal,
                right: (rotate<0)? textWidth*cosVal + fontSize*sinVal : textWidth*cosVal,
                top : (rotate<0)? textWidth*sinVal : 0,
                bottom : (rotate<0)? fontSize*cosVal : textWidth*sinVal + fontSize*cosVal,
              }
            }

            if( textMetric.posX === 960 && centerX){
              // x축 가운데 정렬인 경우
              console.log(`justify center ${textMetric.posX} ~ ${textMetric.left}/${textMetric.right}`)
              textMetric.posX = textMetric.posX - ( (rotate<0)? textMetric.left+textMetric.right : textMetric.right-textMetric.left )/2
            }else if(textMetric.posX + textMetric.right > 1820){
              console.log(`moving left ${textMetric.posX} - ${textMetric.right}`)
              // 오른쪽 경계 밖으로 나간 경우
              textMetric.posX -= textMetric.right
            }else if(textMetric.posX - textMetric.left < 100){
              // 왼쪽 경계 밖으로 나간 경우
              console.log(`moving right ${textMetric.posX} + ${textMetric.right}`)
              textMetric.posX += textMetric.left;
            }

            if(textMetric.posY === 540 && centerY){
              // y축 가운데 정렬인 경우
              console.log(`Align center ${textMetric.posY} ~ ${textMetric.top}/${textMetric.bottom}`)
              textMetric.posY = textMetric.posY - ( (rotate<0)? (textMetric.top-textMetric.bottom) : textMetric.bottom )/2
            }else if( textMetric.posY + textMetric.bottom > 1000){
              // 아래쪽 경계 밖으로 나간 경우
              console.log(`moving up ${textMetric.posY} - ${textMetric.bottom}`)
              if((textMetric.posY + textMetric.bottom) > correctionBottom){
                correctionBottom = 1000 - (textMetric.posY + textMetric.bottom);
              }
            }else if(textMetric.posY - textMetric.top < 120){
              // 위쪽 경계 밖으로 나간 경우
              console.log(`moving down ${textMetric.posY} + ${textMetric.top}`)
              textMetric.posY += textMetric.top;
            }

            if((textMetric.posX - textMetric.left) < 100){
              const correction = 100 - (textMetric.posX - textMetric.left)
              if(correctionLeft < correction ){
                correctionLeft = correction
              }
            }

            if((textMetric.posY - textMetric.top) < 120){
              const correction = 120 - (textMetric.posY - textMetric.top )
              if(correctionTop < correction){
                correctionTop = correction
              }
            }

            console.log(`Scene${idx} text${txtIdx} metric changed ====>\n`, JSON.parse(JSON.stringify(textMetric)))

            sceneData[idx].textList[txtIdx].posX = Math.floor(textMetric.posX)
            sceneData[idx].textList[txtIdx].posY = Math.floor(textMetric.posY)
            sceneData[idx].textList[txtIdx].height = Math.floor(fontSize)
            console.groupEnd()
          }

          if(correctionLeft!==0 || correctionTop!==0 || correctionBottom!==0){
            console.log("correcting position left :: ", correctionLeft)
            console.log("correcting position top :: ", correctionTop)
            for(let txtIdx=0; txtIdx<sceneData[idx].textList.length; txtIdx++){
              sceneData[idx].textList[txtIdx].posX += correctionLeft;
              sceneData[idx].textList[txtIdx].posY += correctionTop;
              sceneData[idx].textList[txtIdx].posY += correctionBottom;
            }
          }
          console.groupEnd()
          sceneData[idx].effect=template.sceneEffect[idx%template.textPos.length].effect
        } 

        finalScenario.scenes = sceneData;

        if(!scenarioContext.useLastEnding){
          finalScenario.scenes[finalScenario.scenes.length-1].textList[0].effect="search"
          finalScenario.scenes[finalScenario.scenes.length-1].textList[0].text=shopInfoContext.shopName
          finalScenario.scenes[finalScenario.scenes.length-1].textList[0].posX=0;
          finalScenario.scenes[finalScenario.scenes.length-1].textList[0].posY=0;
          finalScenario.scenes[finalScenario.scenes.length-1].textList[0].fontColor="#000000";
          finalScenario.scenes[finalScenario.scenes.length-1].textList[0].height=50
        }
        scenarioContext.setFinalScenario(finalScenario);
      }
  
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
      /////////////////////////// 탬플릿 생성용 //////////////////////////////////////
    }


    //// 렌더링 리퀘스트 생성

    let screenEffectList=[];
    let shadowEffectList =[];
    let ttsVolumeList =[];

    if(!editorContext.isEdited){
      const effectDirectionList=[];
  
      for(let i=0; i<sceneData.length; i++){
        const promise = await new Promise(async (resolve, reject)=>{
          try{
            const targetFileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === sceneData[i].fileName)
            if(targetFileIndex>=0){
              if(shopInfoContext.editedFiles[targetFileIndex].mainCategory==="image"){
                const img = new Image();
                const url = await URL.createObjectURL(shopInfoContext.editedFiles[targetFileIndex].file);
                img.onload = () =>{
                  const ratio = img.width/img.height;
                  // console.log(`Aspect ratio of ${shopInfoContext.editedFiles[i].file.name} :: ${ratio}`);
                  if(ratio===16/9){
                    // console.log(`${shopInfoContext.editedFiles[targetFileIndex].file.name} :: [16 : 9]`)
                    effectDirectionList.push("none")
                  }else if(ratio>16/9){
                    // console.log(`${shopInfoContext.editedFiles[targetFileIndex].file.name} wide :: [${(ratio*9).toFixed(2)} : 9]`)
                    effectDirectionList.push("horizontal")
                  }else if(ratio<16/9){
                    // console.log(`${shopInfoContext.editedFiles[targetFileIndex].file.name} tall :: [${(ratio*9).toFixed(2)} : 9]`)
                    effectDirectionList.push("vertical")
                  }
                  URL.revokeObjectURL(url);
                  resolve(true);
                }
                img.onerror = () => {
                  effectDirectionList.push("none")
                  resolve(true)
                }
                img.src = url;
              }else{
                effectDirectionList.push("none");
                resolve(true);
              }
            }else{
              effectDirectionList.push("none")
              resolve(true)
            }
          }catch(err){
            console.error("measuring aspect ratio error ::: ", err)
            effectDirectionList.push("none")
            reject(true)
          }
        })
      }

      for(let sceneIndex=0; sceneIndex<sceneData.length; sceneIndex++){
        const enableBefore = (sceneIndex===0)?runRullet(90):screenEffectList[sceneIndex-1].after.enable;
        let enableAfter = false;
        const reverseBefore = (sceneIndex===0)?runRullet(50):screenEffectList[sceneIndex-1].after.reverse;
  
        if(enableBefore){
          if(sceneIndex<sceneData.length-1){
            if(effectDirectionList[sceneIndex]===effectDirectionList[sceneIndex+1]){
              enableAfter = false;
            }else{
              enableAfter = runRullet(90);
            }
          }else{
            enableAfter = false;
          }
        }else{
          enableAfter = runRullet(90);
        }
        
        const newScreenEffect={
          before:{
            enable: enableBefore,
            reverse: reverseBefore,
            aspect: effectDirectionList[sceneIndex] || "none",
          },
          after:{
            enable: enableAfter,
            reverse: runRullet(90),
            aspect: effectDirectionList[sceneIndex+1]  || "none"
          }
        }
        screenEffectList.push(newScreenEffect)
      }

      for(let i=0; i<sceneData.length; i++){
        let textShadowList = []
        for(let idx=0; idx<sceneData[i].textList.length; idx++){
          const newShadow = {
            color:"#000000",
            opacity:0.1,
            blur:5,
            posX:0,
            posY:0,
          }
          textShadowList.push(newShadow);
        }
        shadowEffectList.push(textShadowList)
        ttsVolumeList.push(100)
      }
      editorContext.setSceneTextShadowEffectList(shadowEffectList)
      editorContext.setScreenEffectList(screenEffectList)
      editorContext.setTtsVolumeList(ttsVolumeList)
    }else{
      screenEffectList = editorContext.screenEffectList;
      shadowEffectList = editorContext.sceneTextShadowEffectList;
      ttsVolumeList = editorContext.ttsVolumeList;
    }


    for(let sceneIndex=0; sceneIndex<sceneData.length; sceneIndex++){

      let renderData = JSON.parse(JSON.stringify(sceneData[sceneIndex]));
      renderData.textList.sort((a, b) => b.ypos - a.ypos);
      if(isLogging){
        console.log('render data sorted',renderData)
      }

      let nextData

      const videoRequestSubList = [];
      if(sceneIndex===sceneData.length-1){
        nextData = sceneData[sceneIndex]
      }else{
        nextData = sceneData[sceneIndex+1]
      }      

      
      for(let textIndex=0; textIndex<renderData.textList.length+1; textIndex++){
        TOTAL_RENDER_COUNT++;

        // 텍스트수+1 번 렌더링
        // 텍스트 렌더링 + 화면전환        

        const screenBeforeAlt = (textIndex === 0) ? renderData.layoutList[0].path : `${assetId}_scene_${sceneIndex}_${textIndex-1}.mp4`
        const screenBeforeAltType = (textIndex === 0) ? renderData.layoutList[0].type : "비디오" // t("pages-editvideo.m5")
        
        let textAnimation
        let text
        let isLast
        let backgroundAnimation
        let xPos
        let yPos
        let fontSize
        let fontFile
        let fontColor
        let outputFileName
        let rotate
        let width

        const fontIndex = fontData.findIndex(item=>item.fontName === renderData.textList[0].font)
        const fontFileName = fontData[fontIndex].fontFile
        // const fontFileName = renderData.textList[0].font


        if(textIndex===renderData.textList.length){

          let screenEffect = matchScreenEffect(renderData.effect);
          if(sceneIndex === sceneData-1){ screenEffect = 'none'}
          else if(sceneIndex === sceneData-2){ screenEffect = 'fadein'}

          textAnimation = 'none'
          text=' '
          isLast =true
          backgroundAnimation = screenEffect
          xPos = 0
          yPos = 0
          fontSize = 1
          width = 1
          fontFile = ''
          fontColor = '#FFFFFF'
          outputFileName = `${assetId}_scene_${sceneIndex}.mp4`
          rotate=0
        }else{
          if(sceneIndex === sceneData.length-1){
            if(scenarioContext.useLastEnding){
              textAnimation = matchTextEffect(renderData.textList[textIndex].effect)
              rotate = renderData.textList[textIndex].rotate
              text= renderData.textList[textIndex].text
              isLast = false;
              backgroundAnimation = 'none'
              xPos = renderData.textList[textIndex].posX;
              yPos = renderData.textList[textIndex].posY;
              fontSize = renderData.textList[textIndex].height;
              width = renderData.textList[textIndex].width;
              fontFile = fontFileName
              fontColor = renderData.textList[textIndex].fontColor
              outputFileName = `${assetId}_scene_${sceneIndex}_${textIndex}.mp4`
            }else{

              let searchTextPosX=0;
              const shopNameLength = shopInfoContext.shopName.length;
              if(shopNameLength<=3){searchTextPosX=775}
              else if(shopNameLength<=5){searchTextPosX=648}
              else if(shopNameLength<=8){searchTextPosX=549}
              else if(shopNameLength<=13){searchTextPosX=419}
              else {searchTextPosX=289}

              textAnimation = "search"
              rotate = renderData.textList[textIndex].rotate
              text= shopInfoContext.shopName
              isLast = false;
              backgroundAnimation = 'none'
              // xPos = renderData.textList[textIndex].posX;
              // yPos = renderData.textList[textIndex].posY;
              xPos = searchTextPosX
              yPos = 510
              width = 100
              fontSize = 50;
              fontFile = fontFileName
              fontColor = renderData.textList[textIndex].fontColor
              outputFileName = `${assetId}_scene_${sceneIndex}_${textIndex}.mp4`
            }
          }else{
            textAnimation = matchTextEffect(renderData.textList[textIndex].effect)
            rotate = renderData.textList[textIndex].rotate
            text= renderData.textList[textIndex].text
            isLast = false;
            backgroundAnimation = 'none'
            // console.log(`scene index :: ${sceneIndex}/${sceneData.length} `, template)
            xPos = renderData.textList[textIndex].posX;
            yPos = renderData.textList[textIndex].posY;
            fontSize = renderData.textList[textIndex].height;
            width = renderData.textList[textIndex].width;
            fontFile = fontFileName
            fontColor = renderData.textList[textIndex].fontColor
            outputFileName = `${assetId}_scene_${sceneIndex}_${textIndex}.mp4`
          }
        }        

        
        const fontIdx = fontData.findIndex(font=>font.fontName===renderData.textList[0].font)
        const fontFamily = fontData[fontIdx]["font-family"];
        ////// 레이아웃 강제 수정 ////
        // if(useTemplate){

        //   const fontInfo = `${renderData.textList[textIndex]? renderData.textList[textIndex].height : 10}px ${fontFamily}`
        //   let textWidth = Math.floor(getTextWidth(text, fontInfo));
          
        //   if(xPos===960){          
        //     xPos = Math.max(960 - textWidth/2, 100)          
        //   }
  
        //   if(yPos=== 540){
        //     yPos = 540 - fontSize/2
        //   }
        //   if(isLogging){
        //     console.log(`scene${sceneIndex} text${textIndex} textWidth :: ${textWidth}, height:${renderData.textList[textIndex]?.height}`)
        //     console.log(`scene${sceneIndex} text${textIndex} text ::: ${renderData.textList[textIndex]?.text}`)
        //   }
  
        //   if(textWidth > 1920*0.9){
        //     const ratio = (1920*0.9)/textWidth;
        //     fontSize = fontSize * ratio;
  
        //     const newFontInfo = `${fontSize}px ${fontFamily}`;
        //     textWidth = Math.floor(getTextWidth(text, newFontInfo));
        //     if(isLogging){
        //       console.log(`scene${sceneIndex} text${textIndex} replaced width :::${textWidth}`);
        //     }
        //   }
  
        //   if(xPos + textWidth > 1920*0.95){
        //     xPos = (1920*0.9)-textWidth + (1920*0.05);
        //     if(isLogging){
        //       console.log(`scene${sceneIndex} text${textIndex} crossed boundary right`)
        //       console.log(`scene${sceneIndex} text${textIndex} xPos => ${xPos}`)
        //     }
        //   }
  
        //   if(yPos + fontSize > 1080*0.95){
        //     yPos = (1080*0.9) - fontSize;
        //     if(isLogging){
        //       console.log(`scene${sceneIndex} text${textIndex} crossed boundary bottom`)
        //       console.log(`scene${sceneIndex} text${textIndex} yPos => ${yPos}`)
        //     }
        //   }
  
        //   fontSize = Math.floor(fontSize);
        // }


        if(sceneIndex === sceneData.length-1){
          backgroundAnimation = 'none'
        }        
        let renderTime = renderData.time;
        if(sceneIndex === sceneData.length-1){
          renderTime = renderData.time - 1;
        }

        let shadow = true;
        // if(sceneIndex === sceneData.length-1){
        //   shadow = false;
        // }
        if(screenBeforeAlt==="search"){
          shadow = false;
        }

        let genRequest = {
          useScroll:scrolling,
          isDev: process.env.REACT_APP_BTV_TOWN_CALLBACK_URL.includes("stage")? true:false,
          serverType:process.env.REACT_APP_SERVER_TYPE,
          inputBucket:process.env.REACT_APP_INPUT_BUCKET_NAME,
          outputBucket:process.env.REACT_APP_OUTPUT_BUCKET_NAME,
          assetId: sessionStorage.getItem("ASSET_ID"),
          shopName: shopInfoContext.shopName,
          sceneIndex: sceneIndex,
          textIndex: textIndex,
          isLast: isLast,
          animType: textAnimation,
          string: text,
          imageAnim: backgroundAnimation,
          fontSize: fontSize,
          width: width,
          fontFile: fontFile,
          fontColor: fontColor,
          outputPath: outputFileName,
          videoSize: '1920x1080',
          screenBefore: screenBeforeAlt,
          screenBeforeType: screenBeforeAltType,
          screenAfter: nextData.layoutList[0].path,
          screenAfterType: nextData.layoutList[0].type,
          tts: renderData.narrationInfo.path,
          xpos: xPos,
          ypos: yPos,
          textDelay: 0,
          screenDelay: renderData.time-0.6,
          duration: renderData.time,
          afterDuration: sceneIndex===sceneData.length-1 ? -1 : sceneData[sceneIndex+1].time,
          hasShadow: shadow,
          shadowEffect: shadowEffectList[sceneIndex],
          TTSDelay: 0,
          colors: renderData.colorList.slice(0,5),
          animSpeed: 1,
          mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
          enableScreenEffectBefore: screenEffectList[sceneIndex].before,
          enableScreenEffectAfter: screenEffectList[sceneIndex].after,
          rotate:rotate,
          useTemplate: useTemplate,
          languageCode: shopInfoContext.language,
          filter: sceneData.filter,
          nextFilter: nextData.filter
          // enableScreenEffect: screenEffect,
        }
        
        if(!scenarioContext.useTemplate){
          genRequest = {
            useScroll:scrolling,
            isDev: process.env.REACT_APP_BTV_TOWN_CALLBACK_URL.includes("stage")? true:false,
            serverType:process.env.REACT_APP_SERVER_TYPE,
            inputBucket:process.env.REACT_APP_INPUT_BUCKET_NAME,
            outputBucket:process.env.REACT_APP_OUTPUT_BUCKET_NAME,
            assetId: sessionStorage.getItem("ASSET_ID"),
            shopName: shopInfoContext.shopName,
            sceneIndex: sceneIndex,
            textIndex: textIndex,
            isLast: isLast,
            animType: textAnimation,
            string: text||"",
            imageAnim: backgroundAnimation,
            fontSize: renderData.textList[textIndex]? renderData.textList[textIndex].height:10,
            width: renderData.textList[textIndex]? renderData.textList[textIndex].width:10,
            fontFile: fontFile,
            fontColor: fontColor,
            outputPath: outputFileName,
            videoSize: '1920x1080',
            screenBefore: screenBeforeAlt,
            screenBeforeType: screenBeforeAltType,
            screenAfter: nextData.layoutList[0].path,
            screenAfterType: nextData.layoutList[0].type,
            tts: renderData.narrationInfo.path,
            xpos: renderData.textList[textIndex]? renderData.textList[textIndex].posX:0,
            ypos: renderData.textList[textIndex]? renderData.textList[textIndex].posY:0,
            textDelay: 0,
            screenDelay: renderData.time-0.6,
            duration: renderData.time,
            afterDuration: sceneIndex===sceneData.length-1 ? -1 : sceneData[sceneIndex+1].time,
            hasShadow: shadow,
            shadowEffect: shadowEffectList[sceneIndex],
            TTSDelay: 0,
            colors: renderData.colorList.slice(0,5),
            animSpeed: 1,
            mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
            enableScreenEffectBefore: screenEffectList[sceneIndex].before,
            enableScreenEffectAfter: screenEffectList[sceneIndex].after,
            rotate:rotate,
            useTemplate: useTemplate,
            languageCode: shopInfoContext.language,
            filter: sceneData.filter,
            nextFilter: nextData.filter
            // enableScreenEffect: screenEffect,
          }
        }


        for(let idx = 0; idx<textIndex; idx++ ){
          const prevReq = videoRequestSubList[idx]
          if(isLogging){
            console.log(`${prevReq.ypos} < ${genRequest.ypos} && ${genRequest.ypos} < ${(prevReq.ypos + prevReq.fontSize)}`)
          }
          if( prevReq.ypos < genRequest.ypos && genRequest.ypos < (prevReq.ypos + prevReq.fontSize)){
            const prevFontInfo = `${prevReq.fontSize}px ${fontFamily}`
            const prevWidth = getTextWidth(prevReq.string ,prevFontInfo)
            if(isLogging){
              console.log(`prev width :: ${prevWidth}`)
              console.log(`${prevReq.xpos} < ${genRequest.xpos} && ${genRequest.xpos} < ${(prevReq.xpos + prevWidth)}`)
              console.log(`scene${sceneIndex} text${textIndex} move to ${prevReq.ypos - (genRequest.fontSize + 10)}`)
            }

            genRequest.ypos = prevReq.ypos - (genRequest.fontSize + 10)
          }
        }
        videoRequestSubList.push(genRequest);
      }      

      videoRequestList.push(videoRequestSubList);
    }
    if(isLogging){
      console.log("video request list : ", videoRequestList)
      console.log("rendering request added last :: ", videoRequestList);
      console.log("screen effects :: ",screenEffectList)
    }
        
    //// 렌더링 API 호출      
    try{
      // for(let sceneIndex=0; sceneIndex<finalSceneData.length; sceneIndex++){
      for(let sceneIndex=0; sceneIndex<videoRequestList.length; sceneIndex++){
        if(isLogging){
          console.log(`scene${sceneIndex} :: ${videoRequestList[sceneIndex]}`)
        }

        const renderData = sceneData[sceneIndex];

        if(renderData){
          videoLength += renderData.time;
        }

        // for(let textIndex=0; textIndex<finalSceneData[sceneIndex].textList.length+1; textIndex++){
        for(let textIndex=0; textIndex<videoRequestList[sceneIndex].length; textIndex++){
          try{
            const genVideoPromise = await new Promise(async (resolve, reject)=>{          
              const genRequest = videoRequestList[sceneIndex][textIndex];
              if(isLogging){
                console.log(`scene ${sceneIndex}_${textIndex} ::: `,genRequest)
              }

              const result = await WebPostRenderScene(genRequest);
              if(isLogging){
                console.log('render result ::: ',result)
              }
              if(result.result===0){
                if(sceneIndex===0 && textIndex===0 ){
                  setStarted(true);
                  // startedRef.current = true;
                }
                try{
                  const videoProgressPromise = await new Promise(async (resolve,reject)=>{  
                    const progressInterval = setInterval(async ()=>{
                      const progressResult = await WebGetRenderScene({assetId: assetId})
                      if(isLogging){
                        console.log('progress result ::: ', progressResult)
                      }
                      if(progressResult.result===0){
                        if(progressResult.progress_rate<0){
                          clearInterval(progressInterval)
                          console.error("rendering error");
                          reject("rendering error");
                        }
                        
                        let textCount;
                        if(sceneData[sceneIndex]){
                          textCount = sceneData[sceneIndex].textList.length+1;
                        }else{
                          textCount = 2
                        }
                        
                        const sceneProgress = (textIndex + progressResult.progress_rate/100)/textCount
    
                        const progress = ((sceneIndex + sceneProgress)/(sceneData.length+2))*100;
    
                        setGenProgress(Math.round(progress));
    
                        switch(progressResult.status){
                          case "progress":
                            console.log(`rendering index ${sceneIndex}_${textIndex} on progress`);
                            break;
                          case "done":
                            clearInterval(progressInterval)
                            console.log(`rendering index ${sceneIndex}_${textIndex} completed`);
                            resolve(true);
                            break;
                          case "failed":
                            clearInterval(progressInterval)
                            console.log(`rendering index ${sceneIndex}_${textIndex} failed`);
                            reject(`rendering index ${sceneIndex}_${textIndex} failed`)
                            break;
                        }
                      }else{
                        clearInterval(progressInterval)
                        reject(progressResult)
                      }
                    }, 1000)
                    intervalIdsRef.current.push(progressInterval)
                  })
                  resolve(videoProgressPromise);
                }catch(err){
                  reject(err);
                }
              }else{
                reject(`${sceneIndex} render faild`)
              }
            })
          }catch(err){
            console.error('rendering failed :: ', err);
            setAlertTitle(t("pages-editvideo.m6"))
            setAlertDescription(t("pages-editvideo.m7"))
            setShowRenderFailAlert(prev=>true)
            return
          }
        }
      }
    }catch(err){
      console.error("rendering failed",err)
      setAlertTitle(t("pages-editvideo.m6"))
      setAlertDescription(t("pages-editvideo.m7"))
      setShowRenderFailAlert(prev=>true)
      return
    }
    
    let aiImageUsed = false
    scenarioContext.finalScenario.scenes.map(scene=>{if(scene.type==="AI 생성 이미지"){aiImageUsed=true}}) //t("pages-editvideo.m8")

    const concatRequest = {
      isDev: process.env.REACT_APP_BTV_TOWN_CALLBACK_URL.includes("stage")? true:false,
      serverType:process.env.REACT_APP_SERVER_TYPE,
      inputBucket:process.env.REACT_APP_INPUT_BUCKET_NAME,
      outputBucket:process.env.REACT_APP_OUTPUT_BUCKET_NAME,
      assetId: sessionStorage.getItem("ASSET_ID"),
      length: sceneData.length,
      duration: videoLength,
      bgmPath: scenarioContext.finalScenario.bgmList[scenarioContext.selectedBgmIndex||0].path,
      bgmVolume: editorContext.bgmVolume,
      ttsPathList: scenarioContext.finalScenario.scenes.map(scene=>{
        const regex = /[a-zA-Z가-힣0-9]/;
        const enableTTS = typeof scene.narrationInfo.text === "string" && regex.test(scene.narrationInfo.text)
        if(enableTTS){
          return scene.narrationInfo.path
        }
      }).filter(path=>path!==undefined),
      ttsVolumeList: ttsVolumeList.map((volume, index)=>{
        const regex = /[a-zA-Z가-힣0-9]/;
        const scene = scenarioContext.finalScenario.scenes[index];
        const enableTTS = typeof scene.narrationInfo.text === "string" && regex.test(scene.narrationInfo.text)
        if(enableTTS){
          return volume
        }
      }).filter(volume=>volume!==undefined),
      aiEnabled: aiImageUsed,
      mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
      languageCode: shopInfoContext.language,
    }
    if(isLogging){
      console.log("concat req :: ", concatRequest)       
    }

    try{
      const concatResult = await WebPostConcat(concatRequest);
      if(isLogging){
        console.log('concat result ::',concatResult)
      }
  
      if(concatResult.result===0){
        try{
          const concatPromise = await new Promise(async (resolve,reject)=>{        
            const concatInterval = setInterval(async ()=>{
              const request = {
                assetId: sessionStorage.getItem("ASSET_ID")
              }
              const concatProgressResult = await WebGetConcat(request);

              if(isLogging){
                console.log('concat progress result ::', concatProgressResult)
              }
              if(concatProgressResult.result===0){
                const progress = Math.floor((sceneData.length+1 + (concatProgressResult.progress_rate/100))/(sceneData.length+2)*100)
                setGenProgress(progress)
    
                switch(concatProgressResult.status){
                  case "progress":
                    console.log("concat on progressing")
                    break;
                  case "done":
                    clearInterval(concatInterval);
                    if(concatProgressResult.videoUrl){
                      if(isLogging){
                        console.log("got s3 url :: ", concatProgressResult.videoUrl)
                      }
                      setS3VideoUrl(concatProgressResult.videoUrl);
                      editorContext.setOutputVideoBucket(concatProgressResult.videoBucket);
                      editorContext.setOutputVideoKey(concatProgressResult.videoKey);
                      editorContext.setOutputPresignedUrl(concatProgressResult.videoUrl);
                      editorContext.setOutputVideoObjectUrl(concatProgressResult.videoObjectUrl);
                    }
                    resolve(true);
                    break;
                  case "failed":
                    clearInterval(concatInterval);
                    reject('concat failed');
                    break;
                }
              }else{
                clearInterval(concatInterval);
                console.log("concat failed");
                reject('concat failed');
              }
            }, 1000)
            intervalIdsRef.current.push(concatInterval)
          })
          if(concatPromise===true){
            const fileRequest={
              assetId: sessionStorage.getItem("ASSET_ID"),
            }
            if(isLogging){
              console.log("request output file ::: ", fileRequest)
            }
           
            const outputFileResult:any = await WebGetConcatFile(fileRequest);
            if(outputFileResult.status===200){
              const blob = new Blob([outputFileResult.data],{type:"video/mp4"})
              const url = URL.createObjectURL(blob);
              setOutputURL(url);
              editorContext.setOutputVideoUrl(url);
              setVideoCreated(true);
              editorContext.setVideoCreated(true);
              shopInfoContext.setCompletedStep(4);
              shopInfoContext.setIsGenerating(false);
            }else{
              console.log("failed retrieving file")
              throw Error("concat failed");
            }  
          }
        }catch(err){
          console.error("failed concat :: ",err)
          setAlertTitle(t("pages-editvideo.m6"))
          setAlertDescription(t("pages-editvideo.m7"))
          setShowRenderFailAlert(true)
          return
        }
      }
    }catch(err){
      console.error("concat failed ::: ", err)
      setAlertTitle(t("pages-editvideo.m6"))
      setAlertDescription(t("pages-editvideo.m7"))
      setShowRenderFailAlert(true)
      return
    }
  }

  const [abortRender, setAbortRender] = useState(false);

  const [showCancelAlert, setShowCancelAlert] = useState(false)
  const closeCancelAlert = () => {setShowCancelAlert(false)};


  const nav = useNavigate();


  const handleConfirmCancel = () => {
    intervalIdsRef.current.forEach((id)=> {console.log("clear ", id);clearInterval(id)});
    intervalIdsRef.current = [];
    shopInfoContext.setIsGenerating(false);
    setShowCancelAlert(false);
    props.setStep(3);
    nav("/scenario")
  }

  const handleCancelVideo = () => {    
    setAlertDescription(t("pages-editvideo.m9"))
    setShowCancelAlert(true)
  }

  const handleToScenario = () => {
    if(videoCreated){
      intervalIdsRef.current.forEach((id)=> clearInterval(id));
      intervalIdsRef.current = [];
      props.setStep(3);
      nav("/scenario")
    }else{
      setAlertDescription(t("pages-editvideo.m9"))
      setShowCancelAlert(true)
    }
  }
    
  function sumSceneTimes(index){
    let sceneTimes = scenarioContext.finalScenario?.scenes.map(scene=>scene.time);
    sceneTimes[sceneTimes.length-1] = sceneTimes[sceneTimes.length-1]-1
    sceneTimes.push(1)
    let sum = 0;
    for(let i=0; i<index; i++){
      sum += sceneTimes[i];
    }
    return sum;
  }

  const handleClickButton = (index) => {  
    if(isLogging){
      console.log("scene select ::: ", index)
    }
    setSelectedScene(prev=>index);
  }

  const {shopName, shopAddress, adTime} = useContext(ShopInfoContext);
  const [timeString, setTimeString] = useState();

  useEffect(()=>{
    const now = new Date();
    const yy = now.getFullYear()
    const mm = ("0"+(now.getMonth()+1).toString()).slice(-2);
    const dd = ("0"+now.getDate().toString()).slice(-2);
    
    setDateString(`${yy}.${mm}.${dd}`);
  },[])



  ///////////////  head  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const head = (
    <Button
      $buttonType="text-l"
      $variant="beige"
      showLeftIcon
      leftIcon="CaretLeft"
      text={t("pages-editvideo.h1")}//text={"Ai 시나리오로 돌아가기"}
      onClick={handleToScenario}
    />
  )

  ///////////////  content  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const tempVideoRef = useRef(null);
  const tempCanvasRef = useRef(null);
  const [isThumbGen, setIsThumbGen]=useState(false);
  const [thumbnails, setThumbnails] = useState([]);



  useEffect(()=>{
    if(videoMetadataLoaded){
      const tempVideoEl = tempVideoRef.current as HTMLVideoElement      
      if(tempVideoRef){
        setOutputVideoDuration(tempVideoEl.duration);
      }
      loadThumbnail();
    }
  },[videoMetadataLoaded])

  const captureFrames = async (targetVideo:HTMLVideoElement) => {
    // console.log(`function captureFrames`)
    targetVideo.currentTime = 0;
    const thumbList = [];
    const canvas = document.createElement('canvas');
    const aspectRatio = targetVideo.videoHeight/targetVideo.videoWidth;
    
    canvas.width = 180;
    canvas.height = 180*aspectRatio;
    const context = canvas.getContext('2d');
    if(!context){
      throw Error("context failed");
    }

    const interval = targetVideo.duration/7

    for(let i=0; i<scenarioContext.finalScenario?.scenes.length; i++){
      targetVideo.currentTime = i;
      await new Promise((resolve) => {
        targetVideo.onseeked = resolve;
      });
      // console.log(`thumb at ${targetVideo.currentTime}`)
      context.drawImage(targetVideo, 0, 0, canvas.width, canvas.height);
      const imageDataUrl = canvas.toDataURL('image/png');
      thumbList.push(imageDataUrl);

    }
    setThumbnails(thumbList);
  };
  
  async function loadThumbnail(){
    try{
      const videoEl = tempVideoRef.current as HTMLVideoElement;
      videoEl.crossOrigin="anonymous"
      const thumbnails = [];
      
      const canvas = document.createElement('canvas');
      canvas.width = 480;
      canvas.height = 270;
      const context = canvas.getContext('2d');
      if(!context){
        throw Error('canvas context failed')
      }
  
      if(videoEl){
        let timeMark = 0
        for(let i=0; i<scenarioContext.finalScenario?.scenes.length; i++){
          videoEl.currentTime = timeMark+0.8
          await new Promise((resolve) => {
            videoEl.onseeked = resolve;
          });
          context.drawImage(videoEl, 0, 0, canvas.width, canvas.height); 
          const imageDataUrl = canvas.toDataURL('image/png')

          thumbnails.push(imageDataUrl)
          timeMark += scenarioContext.finalScenario?.scenes[i].time;
          // videoEl.currentTime = timeMark+1;
        }
        setThumbnails(thumbnails);
        setIsThumbGen(true);
      }      
    }catch(err){
      console.error("Thumbnail error :: ", err)
    }
  }

  const handleLoadMetadata = async (e:any) => {
    setVideoMetadataLoaded(true)
  }

  const [isSaving, setIsSaving] = useState(false);
  const handleClickSave = async () => {
    const adTime = shopInfoContext.adTime;
    if( ((adTime==15)&&(14.5<=outputVideoDuration && outputVideoDuration<=15.5)) ||
        ((adTime==30)&&(29.5<=outputVideoDuration && outputVideoDuration<=30.5)) ){
      setIsSaving(prev=>true);

      let targetURL = s3VideoUrl;      
      if(process.env.REACT_APP_SERVER_TYPE == "DEV" || process.env.REACT_APP_SERVER_TYPE == "STG"){
        //do nothing
      }else{     
        // if server prd, use objkey
        targetURL = editorContext.outputVideoObjectUrl;
      }

      const request = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        renderProgress: 100,
        videoUrl: targetURL,
        duration: adTime
      }
      try{
        const saveResult = await saveData();
        if(saveResult.result!==0){
          throw Error(`Save data error :: ${saveResult.errMsg}`);
        }
        const registerPromise = await new Promise(async(resolve, reject)=>{
          const response = await fetch(`https://${process.env.REACT_APP_BTV_TOWN_API_URL}/media/asset/render/callback`,{
            method:"POST",
            headers:{
              "Content-Type":"application/json"
            },
            body:JSON.stringify(request)
          });
          const result = await response.json();
          editorContext.setAutoExport(false);
          if(result.resultCode === "0000"){
            sessionStorage.clear();
            setAlertTitle(t("pages-editvideo.c1"))
            setAlertDescription(t("pages-editvideo.c2"))
            setShowSuccessAlert(true);
            setIsSaving(prev=>false);
            resolve(true)
          }else{
            reject(result.resultCode);
            setIsSaving(prev=>false);
          }
        })
      }catch(err){
        console.error("Error during register adv :: ", err);
        setAlertTitle(t("pages-editvideo.c1"))
        setAlertDescription(t("pages-editvideo.c3"))
        setShowFailAlert(true);
        setIsSaving(prev=>false);
      }    
    }else{
      setAlertTitle(t("pages-editvideo.c4"))
      setAlertDescription(t("pages-editvideo.c5"))
      setShowDurationAlert(true);
    }
  }

  const handleCompleteRegister = () => {
    setShowSuccessAlert(false);
    window.parent.location.replace(`https://${process.env.REACT_APP_BTV_TOWN_CALLBACK_URL}`)
  }
  
  const handleComplete = (index)=>{
    setSelectedScene(undefined)
  }
  const handleCancel = () => {
    setSelectedScene(undefined) 
  }

  
  const [showDurationAlert, setShowDurationAlert] = useState(false);
  const closeDurationAlert = () => {setShowDurationAlert(false)} 

  const handleConfirmDurationAlert = () => {
    setShowDurationAlert(false);
    props.setStep(5)
    nav("/editor")
  }

  const content = hasExecuted && (
    videoCreated?
      <VideoFrameStyle>
        <VideoPlayer src={outputURL}/>
        {videoCreated && 
          <div style={{display:"none"}}>
            <video crossOrigin="anonymous" muted ref={tempVideoRef} src={outputURL} onLoadedMetadata={handleLoadMetadata}/>
            <canvas ref={tempCanvasRef} />
          </div>
        }        

        {scenarioContext.finalScenario.scenes.map((scene, index)=> {
          if(index === selectedScene-1){
            return (
              <EditScene
                key={`scene_${scene.no}`}
                scene={scene}
                index={index}
                onComplete={()=>{handleComplete(index)}}
                onEndEdit={()=>{handleCancel()}}
                startOnEdit
                noEdit={true}
              />
            )
          }else{
            return(
              <TimelineBlock
                sourceURL={thumbnails[index]}
                state={index===selectedScene? "current" : undefined}
                scene={scene} 
                startTime={sumSceneTimes(index)}
                onClickButton={handleClickButton}
                noEdit
              />
            )
          }
        })}
        {
          showSuccessAlert &&
          <AlertModal
            icon={alertIcon}
            title={alertTitle}
            description={alertDescription}
            show={showSuccessAlert}
            onConfirm={handleCompleteRegister}
            onlyConfirm
          />
        }
        {
          showFailAlert &&
          <AlertModal
            icon={alertIcon}
            title={alertTitle}
            description={alertDescription}
            show={showFailAlert}
            onConfirm={closeFailAlert}
            onlyConfirm
          />
        }
        {
          showCancelAlert&&
          <AlertModal
            icon={alertIcon}
            title={alertTitle}
            description={alertDescription}
            show={showCancelAlert}
            onConfirm={handleConfirmCancel}
            onCancel={closeCancelAlert}
          />
        }
        {
          showDurationAlert&&
          <AlertModal
            icon={alertIcon}
            title={alertTitle}
            description={alertDescription}
            show={showDurationAlert}
            onConfirm={handleConfirmDurationAlert}
            onCancel={closeDurationAlert}
          />
        }
      </VideoFrameStyle>
      :
      <LoadingFrameStyle>
        <VideoLoading value={genProgress} onCancel={handleCancelVideo} started={started}/>
        <VideoInfo
          shopName={shopName}
          address={shopAddress}
          duration={adTime}
          madeDate={dateString}
        />
        {
          showRenderFailAlert &&
          <AlertModal
            icon={alertIcon}
            title={alertTitle}
            description={alertDescription}
            show={showRenderFailAlert}
            onConfirm={closeRenderFailAlert}
            onlyConfirm
          />
        }
        {
          showCancelAlert&&
          <AlertModal
            icon={alertIcon}
            title={alertTitle}
            description={alertDescription}
            show={showCancelAlert}
            onConfirm={handleConfirmCancel}
            onCancel={closeCancelAlert}
          />
        }
      </LoadingFrameStyle>
  )


  ///////////////  foot  /////////////////////////////////////////////////////////////////////////////////////////////////////////


  const handleOnClickSave = async ()=>{
    setIsSaving(prev=>true)
    
    const saveResponse = await saveData();

    setAlertTitle(undefined);
    if(saveResponse.result===0){
      setAlertDescription(t("pages-editvideo.f1"))
    }else{
      console.error(saveResponse.errMsg)
      setAlertDescription(t("pages-editvideo.f2"))
    }
    setShowFailAlert(true);
    setIsSaving(prev=>false)
  }

  const handleOnClickEdit = async () => {
    setIsSaving(prev=>true)
    props.setStep(5)
    nav("/editor")
    setIsSaving(prev=>false)
  }

  async function saveData(){
    
    const filesData=[]
    for(let i=0; i<shopInfoContext.files.length; i++){
      const fileInfo = shopInfoContext.files[i]
      filesData.push({
        s3Key: fileInfo.s3Key,
        mainCategory: fileInfo.mainCategory,
        subCategory: fileInfo.subCategory,
        editInfo: fileInfo.editInfo,
      })
    }

    const editedFilesData=[];
    for(let i=0; i<shopInfoContext.editedFiles.length; i++){
      const editedFileInfo = shopInfoContext.editedFiles[i]
      editedFilesData.push({
        s3Key: editedFileInfo.s3Key,
        mainCategory: editedFileInfo.mainCategory,
        subCategory: editedFileInfo.subCategory,
        editInfo: editedFileInfo.editInfo,
      })
    }
    const infoData = {
      completedStep: shopInfoContext.completedStep,
      shopName: shopInfoContext.shopName,
      shopAddress: shopInfoContext.shopAddress,
      shopCall: shopInfoContext.shopCall,
      shopCategory: shopInfoContext.shopCategory,
      adTime: shopInfoContext.adTime,
      files: filesData,
      editedFiles: editedFilesData,
      aiImages: shopInfoContext.aiImages,
      includeAiImage: shopInfoContext.includeAiImage,
      isGenerating: shopInfoContext.isGenerating,
      imageAnalysisInfo: shopInfoContext.imageAnalysisInfo,
      language: shopInfoContext.language
    }
    const pointData = {
      prosList: shopPointContext.prosList,
      targetList: shopPointContext.targetList,
      customRequirement: shopPointContext.customRequirement,
      requiredTextInfo: shopPointContext.requiredTextInfo,
      requiredFixedTextInfo: shopPointContext.requiredFixedTextInfo,
      customFeatureList: shopPointContext.customFeatureList,
      customCustomerList: shopPointContext.customCustomerList,
      customMoodList: shopPointContext.customMoodList,
      fontFeature: shopPointContext.fontFeature,
      bgmFeature: shopPointContext.bgmFeature,
      narrationCharacterFeature: shopPointContext.narrationCharacterFeature,
    }
    const scenarioData = {
      finalScenario: scenarioContext.finalScenario,
      scenario: scenarioContext.scenario,
      scene: scenarioContext.scene,
      selectedScenario: scenarioContext.selectedScenario,
      selectedTemplate: scenarioContext.selectedTemplate,
      useLastEnding: scenarioContext.useLastEnding,
      generatedImages: scenarioContext.generatedImages,
      generatedVideos: scenarioContext.generatedVideos,
      useTemplate: scenarioContext.useTemplate,
      BGMInfo: scenarioContext.BGMInfo,
      selectedBgmIndex: scenarioContext.selectedBgmIndex,
      selectedTTSActorInfo: scenarioContext.selectedTTSActorInfo,
      editedTTSActorList: scenarioContext.editedTTSActorList,
    }
    
    const editorData = {
      videoCreated: editorContext.videoCreated,
      outputVideoUrl: editorContext.outputVideoUrl,
      outputVideoObjectUrl: editorContext.outputVideoObjectUrl,
      outputPresignedUrl: editorContext.outputPresignedUrl,
      outputVideoBucket: editorContext.outputVideoBucket,
      outputVideoKey: editorContext.outputVideoKey,
      isEdited: editorContext.isEdited,
      screenEffectList: editorContext.screenEffectList,
      sceneTextShadowEffectList: editorContext.sceneTextShadowEffectList,
      ttsVolumeList: editorContext.ttsVolumeList,
      bgmVolume: editorContext.bgmVolume,
      eraserList: editorContext.eraserList,
      genBgList: editorContext.genBgList
    }
    const data={
      infoData: infoData,
      pointData: pointData,
      scenarioData: scenarioData,
      editorData: editorData
    }

    const assetId = sessionStorage.getItem("ASSET_ID");
    
    try{
      const saveRequest={
        assetId: assetId,
        finalInfo: JSON.stringify(data)
      }
      const saveResponse = await WebSave(saveRequest);      
      if(saveResponse.result===0){
        return {result:0, errMsg:""}
      }else{
        const errorMessage = saveResponse.errMsg;
        return {result:-1, errMsg:`failed with result ${saveResponse.result} :: ${errorMessage}`}
      }
    }catch(err){
      console.error("failed to save data", err)
      return {result:-1, errMsg:`failed to save data :: ${err}`}
    }
  }


  const foot =(
    videoCreated&&
    <FootStyle>
      <Button onClick={handleOnClickEdit} $buttonType="capsule" $variant="brand1-subtle" text={t("pages-editvideo.f3")}/>
      {/* <p> </p> */}
      <FootRightStyle>
        {/* <Button onClick={handleOnClickSave} $buttonType="capsule" $variant="brand1-subtle" text={t("pages-editvideo.f4")}/> */}
        {/* <p> </p> */}
        <Button $buttonType="capsule" $variant="brand1" onClick={handleClickSave} text={t("pages-editvideo.f5")} showRightIcon rightIcon="CaretRight" disabled={isSaving}/>
      </FootRightStyle>
    </FootStyle>
  ) 

  return(
    <Container
      head={head}
      content={content}
      foot={foot}
    />
  )
}

export default EditVideo